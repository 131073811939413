import styled from "styled-components";

export const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  justify-content: flex-start;
`;

export const YieldCalculatorHeader = styled.h1`
  color: ${(props) => props.theme.black.dark};
  font-size: 1.5rem;
  font-weight: 500;
  margin: 3rem 0;
`;

export const CalculatorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem;
  gap: 2rem;
  border: 0.0625rem solid ${(props) => props.theme.white.medium};
  border-radius: 1.25rem;

  @media (max-width: ${(props) => props.theme.media.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    margin: 0 .5rem 6rem .5rem;
    width: 22.5rem;
    overflow-x: hidden;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InfoDescriptionContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const ComparisonHeader = styled.h3`
  color: ${(props) => props.theme.black.darkest};
  margin-bottom: 1rem;
`;

export const ComparisonRow = styled.div<{
  activeTier?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 20.625rem;
  padding: 0.75rem 1rem;
  border-radius: 1.25rem;
  border: 0.0625rem solid ${(props) => props.activeTier ? props.theme.white.medium : props.theme.white.lightest};
  margin: .25rem 0;
  ${(props) =>
    props.activeTier &&
    `
    box-shadow: 0 0.063rem 0.5rem 0 ${props.theme.white.medium};
  `}

  @media (max-width: ${(props) => props.theme.media.breakpoints.mobile}) {
    width: 18.25rem;
  }
`;

export const ComparisonYield = styled.p`
  color: ${(props) => props.theme.black.medium};
`;

export const ComparisonTier = styled.p`
  color: ${(props) => props.theme.black.medium};
`;

export const InfoDescription = styled.p`
  color: ${(props) => props.theme.black.darkest};
  margin: 1rem 0;

  &:first-of-type {
    margin-top: 1.5rem;
  }
`;

export const DepositContainer = styled.div`
  color: ${(props) => props.theme.black.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  @media (max-width: ${(props) => props.theme.media.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`;

export const DepositAmount = styled.p`
  color: #344054;
  font-weight: 500;
  line-height: 1.25rem;
`;

export const YieldEarnedActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
`;

export const GetStartedButton = styled.a`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.white.lightest};
  background-color: ${(props) => props.theme.green.medium};
`;

export const CalculateYieldButton = styled.button`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  color: ${(props) => props.theme.white.lightest};
  background-color: ${(props) => props.theme.green.medium};
`;

export const YieldBreakdownCard = styled.div`
  width: 20.25rem;
  height: 24.25rem;
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid ${(props) => props.theme.white.medium};
  border-radius: 1.25rem;
  box-shadow: 0 0.063rem 0.5rem 0 ${(props) => props.theme.white.medium};
  padding: 2rem;
`;

export const YieldBreakdownMonthly = styled.h2`
  color: ${(props) => props.theme.green.medium};
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: .5rem;
`;

export const YieldBreakdownYearly = styled.h3`
  color: ${(props) => props.theme.green.medium};
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
`;

export const YieldBreakdownRate = styled.h3`
  color: ${(props) => props.theme.green.medium};
  font-size: 1.25rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
`;

export const YieldBreakdownLabel = styled.p`
  color: ${(props) => props.theme.black.dark};
  line-height: 1.25rem;
`;

export const ApplyNowButton = styled.a`
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  margin-top: 2rem;
  color: ${(props) => props.theme.green.medium};
  background-color: ${(props) => props.theme.green.lightest};
`;

export const Input = styled.input.withConfig({
  shouldForwardProp: (prop) => !["error"].includes(prop),
}) <{
  error?: boolean;
}>`
  background-color: ${(props) => props.theme.white.lightest};
  border-color: ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium
      : props.theme.white.dark};
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: 0 0.0625rem
    ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium + props.theme.transparency["50%"]
      : props.theme.white.dark + props.theme.transparency["50%"]};
  color: ${(props) => props.theme.black.dark};
  height: 2.875rem;
  outline: none;
  padding: 0 1rem;
  width: 9.25rem;

  &:focus {
    border-color: ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium
      : props.theme.green.light};
    box-shadow: 0 0.0625rem
      ${(props) =>
    props.error === true
      ? props.theme.terracotta.medium + props.theme.transparency["50%"]
      : props.theme.green.light + props.theme.transparency["50%"]};
    outline: none;
  }
  &:focus::placeholder {
    opacity: 0;
  }
  &::placeholder {
    color: ${(props) => props.theme.black.medium};
    opacity: 1;
  }
`;