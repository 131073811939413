const tier1 = 250000;
const tier2 = 10000000;

export const calculateAnnualYield = (depositAmount: number) => {
  let apy = getApyByDepositAmount(depositAmount);

  return depositAmount * apy;
}

export const getApyByDepositAmount = (depositAmount: number): number => {
  if (depositAmount < tier1) {
    return 0.0432;
  } else if (depositAmount < tier2) {
    return 0.0447;
  } else {
    return 0.0462;
  }
}

export const getHumanReadableApy = (depositAmount: number): string => {
  if (depositAmount < tier1) {
    return "4.32%";
  } else if (depositAmount < tier2) {
    return "4.47%";
  } else {
    return "4.62%";
  }
}